import React, { FunctionComponent, useMemo } from 'react'
import useStore, { SetTemplatesFilterAction, SetTemplatesFolderFilterAction, TemplatesFolderFilterSelector } from '___store'

import { Folder, TEMPLATE_DIRECTORY, TEMPLATES_FILTERS } from '___types'
import { useTemplateList } from '___hooks'
import { generateFolderStructure } from 'utilities/helpers'
import { Spinner } from 'assets/svgIconComponents'
import { FolderTree } from 'components/CasusComponents'
import { MainLayoutLeftPaneTemplatesFoldersProps } from '../../../..'

type UseStoreHookResultType = {
  templatesFolderFilter: TemplatesFolderFilterSelector
  setTemplatesFolderFilter: SetTemplatesFolderFilterAction
  setTemplatesFilter: SetTemplatesFilterAction
}

export const Folders: FunctionComponent<MainLayoutLeftPaneTemplatesFoldersProps> = () => {
  const { templatesFolderFilter, setTemplatesFolderFilter, setTemplatesFilter } = useStore(
    'selectTemplatesFolderFilter',
    'setTemplatesFolderFilter',
    'setTemplatesFilter'
  ) as UseStoreHookResultType

  const {
    folders: { data: folders, isLoading },
  } = useTemplateList()

  const folderStructure = useMemo(() => {
    if (!folders) return {}
    const result = generateFolderStructure(folders)
    const mine = Object.assign(result.children?.mine || { children: {} }, { id: TEMPLATES_FILTERS.MINE, label: 'My templates' })
    Object.assign(result.children, { mine })
    const shared = Object.assign(result.children?.shared || { children: {} }, { id: TEMPLATES_FILTERS.SHARED, label: 'Shared with me' })
    Object.assign(result.children, { shared })
    return result
  }, [folders]) as Folder

  if (isLoading)
    return (
      <span>
        <Spinner />
        Loading Folders
      </span>
    )

  return (
    <>
      <FolderTree
        folderStructure={folderStructure.children.mine}
        directory={TEMPLATE_DIRECTORY}
        onClick={(_, fullPath) => {
          setTemplatesFolderFilter(fullPath.split('.'))
          setTemplatesFilter(['-status:trashed'])
        }}
        activeId={templatesFolderFilter.split('.').slice(-1)[0]}
        activePath={templatesFolderFilter}
      />
      <FolderTree
        folderStructure={folderStructure.children.shared}
        directory={TEMPLATE_DIRECTORY}
        onClick={(_, fullPath) => {
          setTemplatesFolderFilter(fullPath.split('.'))
          setTemplatesFilter(['-status:trashed'])
        }}
        activeId={templatesFolderFilter.split('.').slice(-1)[0]}
        activePath={templatesFolderFilter}
      />
    </>
  )
}

Folders.displayName = 'MainLayout-LeftPane-Templates-Folders'

export default Folders
