import React, { FunctionComponent, useState, useMemo, useCallback, useEffect } from 'react'
import useStore, { SelectedDocumentIdSelector, DeselectDocumentsAction } from '___store'

import { useDocument, useUser } from '___hooks'
import { Cross, DocumentPlaceholder } from 'assets/svgIconComponents'
import { Button, Signature } from 'components/CasusComponents'
import {
  Actions,
  Info,
  // Progress,
  Publishing,
  Relocation,
} from './Document.components'
import { MainLayoutRightPaneDocumentProps, mainLayoutRightPaneDocumentClasses as classes } from '../../..'

type UseStoreHookResultType = {
  selectedDocumentId: SelectedDocumentIdSelector
  deselectDocuments: DeselectDocumentsAction
}

export const Document: FunctionComponent<MainLayoutRightPaneDocumentProps> = React.memo(() => {
  const [renaming, setRenaming] = useState(false)
  // const [publishing, setPublishing] = useState(false)
  const { selectedDocumentId, deselectDocuments } = useStore('selectSelectedDocumentId', 'deselectDocuments') as UseStoreHookResultType

  const { data: user } = useUser()
  const {
    data: {
      type,
      // status, progress,
      author,
    } = {},
    duplicate,
    update,
    remove,
    download,
    downloading,
    template: { data: { splits = {} } = {} },
  } = useDocument(selectedDocumentId!)

  const owned = user && author?.id === user.id

  const documentPreview = useMemo(() => {
    //   if (imageLoading) return <Spinner />
    //   return src ? <img src={src} alt="Document Preview" /> : <DocumentPlaceholder randomize />
    return <DocumentPlaceholder randomize />
  }, [])

  const downloadCallback = useCallback(
    (format: 'docx' | 'pdf') => {
      if (typeof download === 'function') download(format)
    },
    [download]
  )

  const renamingCallback = useCallback(() => {
    if (renaming) {
      const inputWrapper = document.getElementById(`MainLayout-RightPane-Document-${selectedDocumentId}-Input`)
      ;(inputWrapper?.firstChild as HTMLPreElement)?.focus()
    } else setRenaming(true)
  }, [renaming, selectedDocumentId])

  const renameCallback = useCallback(
    name => {
      if (name) update({ name })
      setRenaming(false)
    },
    [update]
  )

  const duplicateCallback = useCallback(() => {
    duplicate()
  }, [duplicate])

  const removeCallback = useCallback(() => {
    remove()
    deselectDocuments()
  }, [remove, deselectDocuments])

  // const publishingCallback = useCallback(() => setPublishing(true), [])

  useEffect(() => {
    setRenaming(false)
  }, [selectedDocumentId])

  return (
    <div className={classes.wrapper}>
      <Button onClick={deselectDocuments} onClickBlur transparent>
        <Cross />
      </Button>
      {selectedDocumentId ? (
        <>
          <div className={classes.preview}>{documentPreview}</div>
          <Actions
            id={selectedDocumentId}
            type={type!}
            downloadable={!Object.keys(splits).length}
            downloading={downloading}
            downloadCallback={downloadCallback}
            renameCallback={renamingCallback}
            duplicateCallback={duplicateCallback}
            removeCallback={removeCallback}
          />
          <Info id={selectedDocumentId} renaming={renaming} renameCallback={renameCallback} />
          {/* <Progress id={selectedDocumentId} status={status} progress={progress} publishing={publishing} publishingCallback={publishingCallback} /> */}
          {owned && type !== 'pdf' ? <Publishing id={selectedDocumentId} /> : null}
          {owned ? <Relocation id={selectedDocumentId} /> : null}
          {type === 'pdf' ? (
            <div className={classes.pdfSignature}>
              <span>
                Signature
                <Signature id={selectedDocumentId} />
              </span>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
})

Document.displayName = 'MainLayout-RightPane-Document'

export default Document
