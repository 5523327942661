import { Author, DocumentDownloadFormat, DocumentProgress, DocumentStatus, SignatureSecurityLevel, Signee } from '___types'
import MainLayout from './MainLayout'

import './style.scss'

export type MainLayoutProps = {}
export type MainLayoutHeaderProps = {}
export type MainLayoutHeaderNavigationProps = {}
export type MainLayoutHeaderActionsProps = {}
export type MainLayoutLeftPaneProps = {}
export type MainLayoutLeftPaneFolderTreeProps = {
  directory: string
  id: string
  name: string
  parentId?: string
  className?: string
}
export type MainLayoutLeftPaneDocumentsProps = {}
export type MainLayoutLeftPaneDocumentsFoldersProps = {}
export type MainLayoutLeftPaneDocumentsNewFolderModalContentProps = { createCallack: (name: string) => void; closeCallback: () => void }
export type MainLayoutLeftPaneTemplatesProps = {}
export type MainLayoutLeftPaneTemplatesFoldersProps = {}
export type MainLayoutLeftPaneTemplatesNewFolderModalContentProps = { createCallack: (name: string) => void; closeCallback: () => void }
export type MainLayoutContentProps = {}
export type MainLayoutContentDocumentsProps = {}
export type MainLayoutContentTemplatesProps = {}
export type MainLayoutRightPaneProps = {}
export type MainLayoutRightPaneDocumentProps = {}
export type MainLayoutRightPaneDocumentActionsProps = {
  id: string
  type: 'docx' | 'pdf'
  downloadable: boolean
  downloading: Record<DocumentDownloadFormat, boolean>
  downloadCallback: (format: DocumentDownloadFormat) => void
  renameCallback: () => void
  duplicateCallback: () => void
  removeCallback: () => void
}
export type MainLayoutRightPaneDocumentInfoProps = { id: string; renaming: boolean; renameCallback: (name?: string) => void }
export type MainLayoutRightPaneDocumentProgressProps = {
  id: string
  status?: DocumentStatus
  progress?: DocumentProgress
  publishing: boolean
  publishingCallback: () => void
}
export type MainLayoutRightPaneDocumentProgressDraftProps = {
  id: string
  active: boolean
  publishing: boolean
  publishingCallback: () => void
  progress?: DocumentProgress
}
export type MainLayoutRightPaneDocumentPublishingProps = { id: string }
export type MainLayoutRightPaneDocumentPublishingModalContentContext = {
  notifyEmail: string
  includeEmail: boolean
  previewAvailable: boolean
  signatureAvailable: boolean
  signatureConfig: { concludeText: string; concludeDisabled: boolean; security: SignatureSecurityLevel; signees: Signee[]; message: string }
  expires: boolean
  expirationTime: string | null
  singleUse: boolean
  split: string | null
}
export type MainLayoutRightPaneDocumentPublishingModalContentProps = {
  conclude: (...params: unknown[]) => void
  context: MainLayoutRightPaneDocumentPublishingModalContentContext
  splits?: Record<string, string>
}
export type MainLayoutRightPaneDocumentRelocationProps = { id: string }
export type MainLayoutRightPaneDocumentRelocationModalContentProps = { categoryId?: string | null; conclude: (...params: unknown[]) => void }
export type MainLayoutRightPaneTemplateProps = {}
export type MainLayoutRightPaneTemplateActionsProps = {
  id: string
  renameCallback: () => void
  duplicateCallback: () => void
  removeCallback: () => void
}
export type MainLayoutRightPaneTemplateInfoProps = { id: string; renaming: boolean; renameCallback: (name?: string) => void }
export type MainLayoutRightPaneTemplateSharingProps = {
  id: string
  shareCallback: (email: string, use?: boolean, edit?: boolean) => void
  unshareCallback: (id: string) => void
  sharedWith: Author[]
}
export type MainLayoutRightPaneTemplateCompanySharingProps = {
  id: string
  toggleCallback: (id: string) => void
  isShared: boolean
  toggling: boolean
}
export type MainLayoutRightPaneTemplatePublishingProps = { id: string; link: string }
export type MainLayoutRightPaneTemplateRelocationProps = { id: string }
export type MainLayoutRightPaneTemplateRelocationModalContentProps = {
  id: string
  cancel: (...params: unknown[]) => void
  conclude: (...params: unknown[]) => void
}

export const mainLayoutRightPaneTemplateRelocationModalContentClasses = {
  wrapper: 'MainLayout-RightPane-Template-Relocation-ModalContent-wrapper',
  info: 'MainLayout-RightPane-Template-Relocation-ModalContent-info',
  select: 'MainLayout-RightPane-Template-Relocation-ModalContent-select',
}
export const mainLayoutRightPaneTemplateRelocationClasses = {
  wrapper: 'MainLayout-RightPane-Template-Relocation-wrapper',
  title: 'MainLayout-RightPane-Template-Relocation-title',
}
export const mainLayoutRightPaneTemplatePublishingClasses = {
  wrapper: 'MainLayout-RightPane-Template-Publishing-wrapper',
  title: 'MainLayout-RightPane-Template-Publishing-title',
  form: 'MainLayout-RightPane-Template-Publishing-form',
}
export const mainLayoutRightPaneTemplateSharingClasses = {
  wrapper: 'MainLayout-RightPane-Template-Sharing-wrapper',
  title: 'MainLayout-RightPane-Template-Sharing-title',
  form: 'MainLayout-RightPane-Template-Sharing-form',
  entry: 'MainLayout-RightPane-Template-Sharing-entry',
}
export const mainLayoutRightPaneTemplateCompanySharingClasses = {
  wrapper: 'MainLayout-RightPane-Template-CompanySharing-wrapper',
  title: 'MainLayout-RightPane-Template-CompanySharing-title',
  switch: 'MainLayout-RightPane-Template-CompanySharing-switch',
}
export const mainLayoutRightPaneTemplateInfoClasses = {
  wrapper: 'MainLayout-RightPane-Template-Info-wrapper',
  author: {
    wrapper: 'MainLayout-RightPane-Template-Info-author-wrapper',
    profile: 'MainLayout-RightPane-Template-Info-author-profile',
    name: 'MainLayout-RightPane-Template-Info-author-name',
    email: 'MainLayout-RightPane-Template-Info-author-email',
    relativeTime: 'MainLayout-RightPane-Template-Info-author-relativeTime',
  },
  title: 'MainLayout-RightPane-Template-Info-title',
  entry: 'MainLayout-RightPane-Template-Info-entry',
  description: 'MainLayout-RightPane-Template-Info-description',
}
export const mainLayoutRightPaneTemplateActionsClasses = {
  wrapper: 'MainLayout-RightPane-Template-Actions-wrapper',
  use: 'MainLayout-RightPane-Template-Actions-use',
  edit: 'MainLayout-RightPane-Template-Actions-edit',
}
export const mainLayoutRightPaneTemplateClasses = {
  wrapper: 'MainLayout-RightPane-Template-wrapper',
  preview: 'MainLayout-RightPane-Template-preview',
  actions: mainLayoutRightPaneTemplateActionsClasses,
  info: mainLayoutRightPaneTemplateInfoClasses,
  sharing: mainLayoutRightPaneTemplateSharingClasses,
  publishing: mainLayoutRightPaneTemplatePublishingClasses,
}
export const mainLayoutRightPaneDocumentRelocationModalContentClasses = { wrapper: 'MainLayout-RightPane-Document-Relocation-ModalContent-wrapper' }
export const mainLayoutRightPaneDocumentRelocationClasses = {
  wrapper: 'MainLayout-RightPane-Document-Relocation-wrapper',
  title: 'MainLayout-RightPane-Document-Relocation-title',
}
export const mainLayoutRightPaneDocumentPublishingModalContentClasses = {
  wrapper: 'MainLayout-RightPane-Document-Publishing-ModalContent-wrapper',
  section: 'MainLayout-RightPane-Document-Publishing-ModalContent-section',
  sectionTitle: 'MainLayout-RightPane-Document-Publishing-ModalContent-sectionTitle',
  sectionDescription: 'MainLayout-RightPane-Document-Publishing-ModalContent-sectionDescription',
  sectionOptions: 'MainLayout-RightPane-Document-Publishing-ModalContent-sectionOptions',
  notify: {
    wrapper: 'MainLayout-RightPane-Document-Publishing-ModalContent-notify-wrapper',
  },
  preview: {
    wrapper: 'MainLayout-RightPane-Document-Publishing-ModalContent-preview-wrapper',
    label: 'MainLayout-RightPane-Document-Publishing-ModalContent-preview-label',
    description: 'MainLayout-RightPane-Document-Publishing-ModalContent-preview-description',
  },
  signature: {
    wrapper: 'MainLayout-RightPane-Document-Publishing-ModalContent-signature-wrapper',
    label: 'MainLayout-RightPane-Document-Publishing-ModalContent-signature-label',
    description: 'MainLayout-RightPane-Document-Publishing-ModalContent-signature-description',
    configuration: 'MainLayout-RightPane-Document-Publishing-ModalContent-signature-configuration',
    button: 'MainLayout-RightPane-Document-Publishing-ModalContent-signature-button',
  },
  expiration: {
    wrapper: 'MainLayout-RightPane-Document-Publishing-ModalContent-expiration-wrapper',
    label: 'MainLayout-RightPane-Document-Publishing-ModalContent-expiration-label',
    description: 'MainLayout-RightPane-Document-Publishing-ModalContent-expiration-description',
    input: 'MainLayout-RightPane-Document-Publishing-ModalContent-expiration-input',
  },
  // singleUse: {
  //   wrapper: 'MainLayout-RightPane-Document-Publishing-ModalContent-singleUse-wrapper',
  //   label: 'MainLayout-RightPane-Document-Publishing-ModalContent-singleUse-label',
  //   description: 'MainLayout-RightPane-Document-Publishing-ModalContent-singleUse-description',
  // },
  splits: { wrapper: 'MainLayout-RightPane-Document-Publishing-ModalContent-splits-wrapper' },
}
export const mainLayoutRightPaneDocumentPublishingClasses = {
  wrapper: 'MainLayout-RightPane-Document-Publishing-wrapper',
  header: 'MainLayout-RightPane-Document-Publishing-header',
  modalContent: mainLayoutRightPaneDocumentPublishingModalContentClasses,
}
export const mainLayoutRightPaneDocumentProgressDraftClasses = {
  wrapper: 'MainLayout-RightPane-Document-Progress-Draft-wrapper',
  statusText: 'MainLayout-RightPane-Document-Progress-Draft-statusText',
  callToAction: 'MainLayout-RightPane-Document-Progress-Draft-callToAction',
  progressText: 'MainLayout-RightPane-Document-Progress-Draft-progressText',
  progressValue: 'MainLayout-RightPane-Document-Progress-Draft-progressValue',
  progressBar: 'MainLayout-RightPane-Document-Progress-Draft-progressBar',
  legend: 'MainLayout-RightPane-Document-Progress-Draft-legend',
}
export const mainLayoutRightPaneDocumentProgressClasses = {
  wrapper: 'MainLayout-RightPane-Document-Progress-wrapper',
  draft: mainLayoutRightPaneDocumentProgressDraftClasses,
}
export const mainLayoutRightPaneDocumentInfoClasses = {
  wrapper: 'MainLayout-RightPane-Document-Info-wrapper',
  author: {
    wrapper: 'MainLayout-RightPane-Document-Info-author-wrapper',
    profile: 'MainLayout-RightPane-Document-Info-author-profile',
    name: 'MainLayout-RightPane-Document-Info-author-name',
    email: 'MainLayout-RightPane-Document-Info-author-email',
    relativeTime: 'MainLayout-RightPane-Document-Info-author-relativeTime',
  },
  title: 'MainLayout-RightPane-Document-Info-title',
  entry: 'MainLayout-RightPane-Document-Info-entry',
  description: 'MainLayout-RightPane-Document-Info-description',
}
export const mainLayoutRightPaneDocumentActionsClasses = {
  wrapper: 'MainLayout-RightPane-Document-Actions-wrapper',
  preview: 'MainLayout-RightPane-Document-Actions-preview',
  edit: 'MainLayout-RightPane-Document-Actions-edit',
  download: 'MainLayout-RightPane-Document-Actions-download',
  docx: 'MainLayout-RightPane-Document-Actions-docx',
  pdf: 'MainLayout-RightPane-Document-Actions-pdf',
}
export const mainLayoutRightPaneDocumentClasses = {
  wrapper: 'MainLayout-RightPane-Document-wrapper',
  preview: 'MainLayout-RightPane-Document-preview',
  actions: mainLayoutRightPaneDocumentActionsClasses,
  info: mainLayoutRightPaneDocumentInfoClasses,
  progress: mainLayoutRightPaneDocumentProgressClasses,
  publishing: mainLayoutRightPaneDocumentPublishingClasses,
  pdfSignature: 'MainLayout-RightPane-Document-pdfSignature',
}
export const mainLayoutRightPaneClasses = {
  wrapper: 'MainLayout-RightPane-wrapper',
  document: mainLayoutRightPaneDocumentClasses,
  template: mainLayoutRightPaneTemplateClasses,
}
export const mainLayoutContentTemplatesNewFolderModalContentClasses = { wrapper: 'MainLayout-Content-Templates-NewFolderModalContent-wrapper' }
export const mainLayoutContentTemplatesClasses = {
  wrapper: 'MainLayout-Content-Templates-wrapper',
  actionBar: {
    wrapper: 'MainLayout-Content-Templates-actionBar-wrapper',
    filter: 'MainLayout-Content-Templates-actionBar-filter',
    filterButtonGroup: 'MainLayout-Content-Templates-actionBar-filterButtonGroup',
    filterExtraButton: 'MainLayout-Content-Templates-actionBar-filterExtraButton',
  },
  table: {
    nameColumn: 'MainLayout-Content-Templates-table-nameColumn',
    statusColumn: 'MainLayout-Content-Templates-table-statusColumn',
    selectColumn: 'MainLayout-Content-Templates-table-selectColumn',
    actionColumn: 'MainLayout-Content-Templates-table-actionColumn',
    actionSet: 'MainLayout-Content-Templates-table-actionSet',
  },
}
export const mainLayoutContentDocumentsClasses = {
  wrapper: 'MainLayout-Content-Documents-wrapper',
  actionBar: {
    wrapper: 'MainLayout-Content-Documents-actionBar-wrapper',
    filter: 'MainLayout-Content-Documents-actionBar-filter',
    filterButtonGroup: 'MainLayout-Content-Documents-actionBar-filterButtonGroup',
    filterExtraButton: 'MainLayout-Content-Documents-actionBar-filterExtraButton',
  },
  table: {
    nameColumn: 'MainLayout-Content-Documents-table-nameColumn',
    typeColumn: 'MainLayout-Content-Documents-table-typeColumn',
    ownerColumn: 'MainLayout-Content-Documents-table-ownerColumn',
    sharingColumn: 'MainLayout-Content-Documents-table-sharingColumn',
    statusColumn: 'MainLayout-Content-Documents-table-statusColumn',
    modifiedColumn: 'MainLayout-Content-Documents-table-modifiedColumn',
    selectColumn: 'MainLayout-Content-Documents-table-selectColumn',
    // actionColumn: 'MainLayout-Content-Documents-table-actionColumn',
    // actionSet: 'MainLayout-Content-Documents-table-actionSet',
  },
}
export const mainLayoutContentClasses = {
  wrapper: 'MainLayout-Content-wrapper',
  documents: mainLayoutContentDocumentsClasses,
  templates: mainLayoutContentTemplatesClasses,
}
export const mainLayoutLeftPaneFolderTreeClasses = {
  wrapper: 'MainLayout-LeftPane-FolderTree-wrapper',
  entries: 'MainLayout-LeftPane-FolderTree-entries',
}
export const mainLayoutLeftPaneTemplatesClasses = {
  wrapper: 'MainLayout-LeftPane-Templates-wrapper',
  newTemplate: 'MainLayout-LeftPane-Templates-newTemplate',
  newFolder: 'MainLayout-LeftPane-Templates-newFolder',
  newFolderModalContent: 'MainLayout-LeftPane-Templates-newFolderModalContent',
  home: 'MainLayout-LeftPane-Templates-home',
  bin: 'MainLayout-LeftPane-Templates-bin',
  // quickAccess: 'MainLayout-LeftPane-Templates-quickAccess',
}
export const mainLayoutLeftPaneDocumentsClasses = {
  wrapper: 'MainLayout-LeftPane-Documents-wrapper',
  newDocument: 'MainLayout-LeftPane-Documents-newDocument',
  extras: 'MainLayout-LeftPane-Documents-extras',
  uploadPdf: 'MainLayout-LeftPane-Documents-uploadPdf',
  newFolder: 'MainLayout-LeftPane-Documents-newFolder',
  newFolderModalContent: 'MainLayout-LeftPane-Documents-newFolderModalContent',
  home: 'MainLayout-LeftPane-Documents-home',
  bin: 'MainLayout-LeftPane-Documents-bin',
  quickAccess: 'MainLayout-LeftPane-Documents-quickAccess',
  signaturePending: 'MainLayout-LeftPane-Documents-signaturePending',
  signed: 'MainLayout-LeftPane-Documents-signed',
}
export const mainLayoutLeftPaneClasses = {
  wrapper: 'MainLayout-LeftPane-wrapper',
  documents: mainLayoutLeftPaneDocumentsClasses,
  templates: mainLayoutLeftPaneTemplatesClasses,
}
export const mainLayoutHeaderClasses = {
  wrapper: 'MainLayout-Header-wrapper',
  navigation: 'MainLayout-Header-navigation',
  homeButton: 'MainLayout-Header-homeButton',
  actions: 'MainLayout-Header-actions',
}
export const mainLayoutClasses = {
  wrapper: 'MainLayout-wrapper',
  header: mainLayoutHeaderClasses,
  leftPane: mainLayoutLeftPaneClasses,
  content: mainLayoutContentClasses,
  rightPane: mainLayoutRightPaneClasses,
} as const

export { MainLayout, mainLayoutClasses as classes }

export default MainLayout
