// import { v4 as uuid } from 'uuid'
import {
  // InfiniteData,
  MutateOptions,
  useMutation,
  // useQueryClient,
} from 'react-query'

import {
  // DOCUMENT_STATUSES,
  DOCUMENTS_FILTERS,
  IDocument,
  SECURITY_LEVELS,
  Segment,
  SignatureSecurityLevel,
  Signee,
} from '___types'
import { documentsAPI } from '___api'
// import { DOCUMENT_LIST_PER_PAGE } from '___api/api.documents'
import {
  QUERY_KEYS,
  // infiniteQueryReplace,
  useFetchDocument,
  useFetchTemplate,
} from '___queries'
import { parseAnswersForDownload } from '.'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

export type RequestDocumentSignatureVariables = {
  id: string
  type: 'regular' | 'pdf'
  securityLevel: SignatureSecurityLevel
  // legislation: string;
  signees: Signee[]
  paragraphs?: Segment[]
  message?: string
  splitId?: string | null
  publicFlow?: boolean
  updateCategory?: string
}
const requestDocumentSignatureMutationFunction = (variables: RequestDocumentSignatureVariables) => {
  if (variables.type === 'regular')
    return documentsAPI.requestDocumentSignature(
      variables.id,
      variables.paragraphs!,
      variables.securityLevel,
      // variables.legislation,
      variables.signees,
      variables.message,
      variables.splitId,
      variables.publicFlow
    )
  return documentsAPI.requestPDFDocumentSignature(variables.id, variables.securityLevel, variables.signees, variables.message)
}

export const useRequestDocumentSignature = (id?: string | null, publicFlow: boolean = false) => {
  // const queryClient = useQueryClient()
  const { data: document } = useFetchDocument(id, publicFlow)
  const { data: template } = useFetchTemplate(document?.templateId, publicFlow)
  const documentSignatureRequestMutation = useMutation<IDocument, unknown, RequestDocumentSignatureVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []),
    requestDocumentSignatureMutationFunction
    // {
    //   onMutate: variables => {
    //     if (publicFlow) return { mutationId: '' }
    //     const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
    //     const mutationId = uuid()
    //     if (currentDocument) {
    //       const originalDocument = currentDocument.original || currentDocument
    //       const optimisticDocument = Object.assign({}, originalDocument, { status: DOCUMENT_STATUSES.LOCKED, mutationId, original: originalDocument })
    //       queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], optimisticDocument)
    //       if (variables.updateCategory)
    //         queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
    //           infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, optimisticDocument)
    //         )
    //     }
    //     return { mutationId }
    //   },
    //   onError: (error, variables, context) => {
    //     if (publicFlow) return
    //     const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
    //     if (currentDocument && currentDocument.mutationId === context?.mutationId) {
    //       queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], currentDocument.original)
    //       if (variables.updateCategory)
    //         queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
    //           infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, currentDocument.original)
    //         )
    //     }
    //   },
    //   onSuccess: (document, variables, context) => {
    //     if (publicFlow) return
    //     const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
    //     if (currentDocument && currentDocument.mutationId === context?.mutationId) {
    //       queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], document)
    //       if (variables.updateCategory)
    //         queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
    //           infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, document)
    //         )
    //     }
    //   },
    //   // onSettled: async (document, error, variables, context) => {
    //   //   if (publicFlow) return
    //   //   // =========================================================================================== //
    //   //   // ==================================== REFETCH DOCUMENTS ==================================== //
    //   //   // =========================================================================================== //
    //   //   if (variables) {
    //   //     const queryKey = [QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? [])
    //   //     queryClient.cancelQueries(queryKey) // async
    //   //     queryClient.invalidateQueries(queryKey) // async
    //   //     queryClient.fetchInfiniteQuery({ queryKey, queryFn: getDocumentListQueryFunction }) // async
    //   //   }
    //   //   // =========================================================================================== //
    //   // },
    // }
  )

  const requestSignatureMutationFunction = (
    signees: Signee[],
    securityLevel: SignatureSecurityLevel = document?.signatureSecurityLevel ?? SECURITY_LEVELS.BASE,
    // legislation?: string,
    message?: string,
    splitId?: string | null,
    category: string = DOCUMENTS_FILTERS.MINE,
    options?: MutateOptions<IDocument, unknown, RequestDocumentSignatureVariables, { mutationId: string }>
  ) => {
    if (!document) return
    if (document.type === 'pdf') documentSignatureRequestMutation.mutate({ id: id!, type: 'pdf', securityLevel, signees, message })
    if (!template) return
    const { answers, languages, externalAPIs } = document
    const [paragraphs] = parseAnswersForDownload(template!, languages, externalAPIs, splitId, answers)
    return documentSignatureRequestMutation.mutate(
      { id: id!, type: 'regular', paragraphs, securityLevel, signees, message, splitId, publicFlow, updateCategory: category },
      options
    )
  }
  return { requestSignature: requestSignatureMutationFunction, requestingSignature: documentSignatureRequestMutation.isLoading }
}

export type RequestDocumentSignatureFunctionType = (
  signees: Signee[],
  securityLevel?: SignatureSecurityLevel,
  // legislation?: string,
  message?: string,
  splitId?: string | null,
  category?: string,
  options?: MutateOptions<IDocument, unknown, RequestDocumentSignatureVariables, { mutationId: string }>
) => void

export default useRequestDocumentSignature
