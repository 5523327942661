import { ExternalAPIs, LanguageValue, ReadonlyRecord, ValuesOf } from '.'
import { Time, Author, SignatureSecurityLevel, Signee } from './types.general'

// ===========================================================================================  //
// ======================================== CONSTANTS ========================================  //
// ===========================================================================================  //
export const DOCUMENTS_FILTERS = {
  MINE: 'mine',
  COMPANY: 'company',
  SHARED: 'shared',
  PUBLIC: 'public',
  TRASHED: 'trashed',
} as const
type DocumentsFilters = typeof DOCUMENTS_FILTERS
type DocumentsFiltersKeys = keyof DocumentsFilters
export type DocumentsFilter = DocumentsFilters[DocumentsFiltersKeys]

const DOCUMENT_FILTER_MAP = {
  [DOCUMENTS_FILTERS.MINE]: 'my',
  [DOCUMENTS_FILTERS.TRASHED]: 'bin',
} as const
type DocumentsFilterMap = typeof DOCUMENT_FILTER_MAP
type DocumentsFilterMapKeys = keyof DocumentsFilterMap
type DocumentsFilterMapValues = DocumentsFilterMap[DocumentsFilterMapKeys]
export type MappedDocumentsFilter = DocumentsFilterMapValues | Exclude<DocumentsFilter, DocumentsFilterMapKeys>

export const mapDocumentsFilter = (documentFilter: string): MappedDocumentsFilter | string =>
  DOCUMENT_FILTER_MAP[documentFilter as DocumentsFilterMapKeys] || documentFilter
export const MAPPED_DOCUMENTS_FILTERS = (Object.entries(DOCUMENTS_FILTERS) as [DocumentsFiltersKeys, DocumentsFilter][]).reduce(
  (acc, [key, value]) => Object.assign(acc, { [key]: mapDocumentsFilter(value) }),
  {}
) as ReadonlyRecord<DocumentsFiltersKeys, MappedDocumentsFilter>
export const unmapDocumentFilter = (filter: string): DocumentsFilter | string => {
  const filterEntry = Object.entries(MAPPED_DOCUMENTS_FILTERS).find(([key, value]) => value === filter)
  if (filterEntry) return DOCUMENTS_FILTERS[filterEntry[0] as DocumentsFiltersKeys]
  return filter
}
// ===========================================================================================  //

export type DocumentDownloadFormat = 'docx' | 'pdf'
export type QuestionnaireOutputEntry = { question: string; answer: string }
export type AnswerRelevance = Record<string, boolean>
export const DOCUMENT_STATUSES = {
  LOCKED: 'LOCKED',
  DRAFT: 'DRAFT',
  FINAL: 'FINAL',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PENDING_SIGNATURE: 'PENDING_SIGNATURE',
  SIGNED: 'EXECUTED',
} as const
export type DocumentStatus = ValuesOf<typeof DOCUMENT_STATUSES>
export interface IListDocument {
  id: string
  type: 'docx' | 'pdf'
  status: DocumentStatus
  name: string
  templateId: string
  category: string

  authorId: string
  created: Time
  edited: Time
  sharedWith: string[]
  isPublic: boolean
  signatureStatus: string

  mutating?: boolean
  mutation?: 'create' | 'update' | 'remove'
}
export type Answer = { id: string; values: string[] }
export type Answers = Answer[]
export type DocumentProgress = { [K in 'private' | 'public']?: { questions: number; answered: number } }
export type DocumentTags = ('approved' | string)[] // add other tags and remove "string" when decided upon
export type DocumentPublishing = {
  notifyEmail?: string
  includeEmail?: boolean
  previewAvailable?: boolean
  signatureAvailable?: boolean
  signatureConfig?: { security?: SignatureSecurityLevel; signees?: Signee[]; message?: string }
  exipres?: boolean
  expirationTime?: string | null
  singleUse?: boolean
  split?: string | null
}
export interface IDocument {
  id: string
  type: 'docx' | 'pdf'
  status: DocumentStatus
  name: string
  templateId: string
  templateName: string
  templateContentVersionId: string
  contentVersionId: string
  category: string

  author: Author
  created: Time
  edited: Time
  sharedWith: (Author & { permissions: { read: boolean; write: boolean } })[]
  isPublic: boolean
  publicSettings?: DocumentPublishing
  approvers: string[]
  answers: Answers
  languages: LanguageValue[]
  integrationEntries: Record<string, string>
  externalAPIs: ExternalAPIs
  signatureSecurityLevel: SignatureSecurityLevel

  progress?: DocumentProgress
  tags?: DocumentTags

  mutating?: boolean
  mutation?: 'create' | 'update' | 'remove'
}
export type PartialDocument = { [K in keyof IDocument]?: IDocument[K] }

export interface IDocumentFolder {
  id: string
  authorId: string
  parentFolderId: string
  name: string
  sharingEnabled: boolean
  sharedWith: Author[]
  author: Author
  created: Time
  edited: Time
}
