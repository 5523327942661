import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { IDocument } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type RemoveDocumentVariables = string
export type RemoveDocumentContext = { mutationId: string }
export type RemoveDocumentMutationOptions = MutateOptions<void, unknown, RemoveDocumentVariables, RemoveDocumentContext>
export type RemoveDocumentFunctionType = (options?: RemoveDocumentMutationOptions) => void
// const removeDocumentMutationFunction = (variables: RemoveDocumentVariables) => documentsAPI.removeDocument(variables)

export const useRemoveDocument = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: IDocument[] | undefined) => IDocument[]) => queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = () => {
    const mutationId = uuid()
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original?: IDocument }
    if (currentDocument) {
      const originalDocument = currentDocument.original || currentDocument
      const optimisticDocument = Object.assign({}, originalDocument, {
        mutationId,
        mutating: true,
        mutation: 'remove',
        original: originalDocument,
      })
      queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], optimisticDocument)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticDocument))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: RemoveDocumentVariables, context: RemoveDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) {
      queryClient.removeQueries([QUERY_KEYS.DOCUMENT, id])
      queryClient.resetQueries([QUERY_KEYS.DOCUMENT, id])
      // queryClient.setQueryData(documentQueryKey, currentDocument.original)
      queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
      queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentDocument.original))
    }
  }

  const onSuccess = (response: void, variables: RemoveDocumentVariables, context: RemoveDocumentContext | undefined) => {
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.DOCUMENT, id] })
    queryClient.resetQueries({ queryKey: [QUERY_KEYS.DOCUMENT, id] })
    updateListing(data => data?.filter(datum => datum.id !== id) || [])
  }

  const documentRemoveMutation = useMutation<void, unknown, RemoveDocumentVariables, RemoveDocumentContext>(
    [QUERY_KEYS.DOCUMENT, id],
    documentsAPI.removeDocument,
    { onMutate, onError, onSuccess }
  )

  const removeMutationFunction: RemoveDocumentFunctionType = options => documentRemoveMutation.mutate(id!, options)
  return { remove: removeMutationFunction, removing: documentRemoveMutation.isLoading }
}

export default useRemoveDocument
