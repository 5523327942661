import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { BEFolder } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type RemoveDocumentFolderVariables = string
export type RemoveDocumentFolderContext = { mutationId: string }
export type RemoveDocumentFolderMutationOptions = MutateOptions<void, unknown, RemoveDocumentFolderVariables, RemoveDocumentFolderContext>
export type RemoveDocumentFolderFunctionType = (options?: RemoveDocumentFolderMutationOptions) => void
// const removeDocumentFolderMutationFunction = (variables: RemoveDocumentFolderVariables) => documentsAPI.removeDocumentFolder(variables)

export const useRemoveDocumentFolder = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: BEFolder[] | undefined) => BEFolder[]) => queryClient.setQueryData([QUERY_KEYS.DOCUMENT_FOLDERS], method)

  const onMutate = () => {
    const mutationId = uuid()
    const replaceMethod = (currentFolder: BEFolder & { mutationId?: string; original?: BEFolder }) => {
      const original = currentFolder.original || currentFolder
      return Object.assign({}, currentFolder, { mutationId, mutating: true, mutation: 'remove', original })
    }
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, replaceMethod))
    return { mutationId }
  }

  const onError = (error: unknown, variables: RemoveDocumentFolderVariables, context: RemoveDocumentFolderContext | undefined) => {
    const currentFolder = (
      queryClient.getQueryData([QUERY_KEYS.DOCUMENT_FOLDERS]) as (BEFolder & { mutationId?: string; original: BEFolder })[]
    ).find(folder => folder.id === id)
    if (currentFolder && currentFolder.mutationId === context?.mutationId) {
      queryClient.cancelQueries([QUERY_KEYS.DOCUMENT_FOLDERS])
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENT_FOLDERS])
      queryClient.fetchQuery([QUERY_KEYS.DOCUMENT_FOLDERS])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentFolder.original))
    }
  }

  const onSuccess = (response: void, variables: RemoveDocumentFolderVariables, context: RemoveDocumentFolderContext | undefined) => {
    updateListing(data => data?.filter(datum => datum.id !== id) || [])
  }

  const documentFolderRemoveMutation = useMutation<void, unknown, RemoveDocumentFolderVariables, RemoveDocumentFolderContext>(
    [QUERY_KEYS.DOCUMENT, id],
    documentsAPI.removeDocumentFolder,
    { onMutate, onError, onSuccess }
  )

  const removeFolderMutationFunction: RemoveDocumentFolderFunctionType = options => documentFolderRemoveMutation.mutate(id!, options)

  return { removeDocumentFolder: removeFolderMutationFunction, removingDocumentFolder: documentFolderRemoveMutation.isLoading }
}

export default useRemoveDocumentFolder
