import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { IDocument, PartializeKeys, SignatureSecurityLevel, Signee } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type PublishDocumentVariables = {
  id: string
  notifyEmail: string
  includeEmail: boolean
  previewAvailable: boolean
  signatureAvailable: boolean
  signatureConfig?: { security: SignatureSecurityLevel; signees: Signee[]; message?: string }
  expires: boolean
  expirationTime?: string | null
  singleUse: boolean
  split?: string | null
  updateCategory?: string
}
export type PublishDocumentContext = { mutationId: string }
export type PublishDocumentMutationOptions = MutateOptions<IDocument, unknown, PublishDocumentVariables, PublishDocumentContext>
export type PublishDocumentFunctionType = (
  notifyEmail?: string,
  includeEmail?: boolean,
  previewAvailable?: boolean,
  signatureAvailable?: boolean,
  signatureConfig?: { security: SignatureSecurityLevel; signees: Signee[]; message?: string },
  expires?: boolean,
  expirationTime?: string | null,
  singleUse?: boolean,
  split?: string | null,
  options?: PublishDocumentMutationOptions
) => void
const publishDocumentMutationFunction = (variables: PublishDocumentVariables) => {
  const payload = Object.assign({}, variables) as PartializeKeys<PublishDocumentVariables, 'id'>
  delete payload.id
  return documentsAPI.publishDocument(variables.id!, payload)
}

export const usePublishDocument = (id?: string | null) => {
  const queryClient = useQueryClient()

  const updateListing = (method: (data: (IDocument & { mutationId?: string })[] | undefined) => IDocument[]) =>
    queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: PublishDocumentVariables) => {
    const mutationId = uuid()
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original?: IDocument }
    if (currentDocument) {
      const originalDocument = currentDocument.original || currentDocument
      const optimisticDocument = Object.assign({}, originalDocument, { mutationId, mutating: true, mutation: 'update', original: originalDocument })
      queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], optimisticDocument)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticDocument))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: PublishDocumentVariables, context: PublishDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) {
      queryClient.removeQueries([QUERY_KEYS.DOCUMENT, id])
      queryClient.resetQueries([QUERY_KEYS.DOCUMENT, id])
      // queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], currentDocument.original)
      queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
      queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
      queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
      // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentDocument.original))
    }
  }

  const onSuccess = (document: IDocument, variables: PublishDocumentVariables, context: PublishDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], document)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, document))
  }

  const documentPublishMutation = useMutation<IDocument, unknown, PublishDocumentVariables, PublishDocumentContext>(
    [QUERY_KEYS.DOCUMENT, id],
    publishDocumentMutationFunction,
    {
      onMutate,
      onError,
      onSuccess,
    }
  )

  const publishMutationFunction: PublishDocumentFunctionType = (
    notifyEmail = '',
    includeEmail = false,
    previewAvailable = false,
    signatureAvailable = false,
    signatureConfig,
    expires = false,
    expirationTime,
    singleUse = false,
    split,
    options
  ) =>
    documentPublishMutation.mutate(
      { id: id!, notifyEmail, includeEmail, previewAvailable, signatureAvailable, signatureConfig, expires, expirationTime, singleUse, split },
      options
    )

  return { publish: publishMutationFunction, publishing: documentPublishMutation.isLoading }
}

export default usePublishDocument
