export interface IUser {
  id: string
  version: 'v1' | 'v2' | 'v3'
  privacy: { docxDownload: boolean; pdfDownload: boolean; gptServicesEnabled: boolean }
  tenantMatadata?: { isMember?: boolean }
  subscriptions?: { status: 'active' | 'inactive' }[]
  type: 'USER_TYPE_CLIENT_ADMIN' | string
  sharedCompanyTemplates: SharedCompanyTemplates
  firstName: string
  lastName: string
  email: string
  imageUrl: string
}

export type SharedCompanyTemplates = {
  enabled: boolean
  templates: string[]
}

export type ExternalServiceField = { id: string; name: string; type: string; value?: string }
export type ExternalService = { id: string; title: string; fields: ExternalServiceField[] }
export type ExternalServiceDisplayEntry = { id: string; firstName: string; lastName: string } // Implement a general solution
export type ExternalServiceEntry<T extends string[] | readonly string[]> = { [K in T[number]]: string }

export const EXTERNAL_SERVICE_TITLES = { 'bamboo-hr': 'Bamboo HR' } as const
export type ExternalServiceId = keyof typeof EXTERNAL_SERVICE_TITLES
export const EXTERNAL_SERVICE_ENTRIES_FIELDS = { 'bamboo-hr': 'employees' } as const

const TENANT_SBV = 'sbv'

export const TENANT_PAYWAY_CHECK_METHODS = {
  [TENANT_SBV]: (user: IUser) => !user.tenantMatadata?.isMember,
  localhost: () => true,
} as const

export type Tenant = keyof typeof TENANT_PAYWAY_CHECK_METHODS
