import React, { FunctionComponent, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import useStore, {
  DocumentsFilterSelector,
  DocumentsFolderFilterSelector,
  SelectedDocumentIdSelector,
  SetDocumentsFilterAction,
  SetDocumentsFolderFilterAction,
  SetSelectedDocumentIdAction,
} from '___store'

import {
  Author,
  BEFolder,
  DOCUMENT_DIRECTORY,
  DOCUMENT_STATUSES,
  DOCUMENTS_FILTERS,
  DocumentStatus,
  IDocument,
  IListDocument,
  RecursiveRecord,
} from '___types'
import { extractValueFromObject } from 'utilities/helpers'
import { useDocumentList, useUser } from '___hooks'
import { Document, HorizontalLines, Plus, Public, VerticalEllipsis } from 'assets/svgIconComponents'
import { Grid, DocumentThumbnail, Button, Table, Column, Dropdown, Breadcrumbs, Profile } from 'components/CasusComponents'
import { MainLayoutContentDocumentsProps, mainLayoutContentDocumentsClasses as classes } from '../../..'

const VIEW_STYLES = ['card-view', 'list-view'] as const
const VIEW_ICONS = [
  <Document key="MainLayout-Content-Documents-Card-View" />,
  <HorizontalLines key="MainLayout-Content-Documents-List-View" />,
] as const
const STATUS_MAP = {
  [DOCUMENT_STATUSES.DRAFT]: 'Draft',
  [DOCUMENT_STATUSES.LOCKED]: 'Locked',
  [DOCUMENT_STATUSES.PENDING_APPROVAL]: 'In approval',
  [DOCUMENT_STATUSES.FINAL]: 'Final',
  [DOCUMENT_STATUSES.PENDING_SIGNATURE]: 'Signature pending',
  [DOCUMENT_STATUSES.SIGNED]: 'Signed',
  [DOCUMENTS_FILTERS.TRASHED]: 'Trashed',
} as const
const TYPE_MAP = {
  regular: 'Document',
  pdf: 'pdf',
} as const

const filterCheck = (filters: string[], key: string, document: RecursiveRecord<string, unknown>) => {
  if (!filters.length) return true
  const value = extractValueFromObject(document, key)
  return filters.some(filter => filter === value)
}

type UseStoreHookResultType = {
  documentsFolderFilter: DocumentsFolderFilterSelector
  documentsFilter: DocumentsFilterSelector
  selectedDocumentId: SelectedDocumentIdSelector
  setSelectedDocumentId: SetSelectedDocumentIdAction
  setDocumentsFolderFilter: SetDocumentsFolderFilterAction
  setDocumentsFilter: SetDocumentsFilterAction
}

export const Documents: FunctionComponent<MainLayoutContentDocumentsProps> = React.memo(() => {
  const history = useHistory()
  const [view, setView] = useState<(typeof VIEW_STYLES)[number]>(VIEW_STYLES[1])
  const { documentsFolderFilter, documentsFilter, selectedDocumentId, setSelectedDocumentId, setDocumentsFolderFilter, setDocumentsFilter } =
    useStore(
      'selectDocumentsFolderFilter',
      'selectDocumentsFilter',
      'selectSelectedDocumentId',
      'setSelectedDocumentId',
      'setDocumentsFolderFilter',
      'setDocumentsFilter'
    ) as UseStoreHookResultType

  const { data: { id: userId } = {} } = useUser()
  const {
    list: { data, isLoading, isFetching, error },
    trashed: { data: trashed },
    folders: { data: folders },
  } = useDocumentList()

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') return error
    if (error instanceof Error) return error.message
    return ''
  }, [error])

  const activeFolderFilter = useMemo(() => documentsFolderFilter.split('.').slice(-1)[0], [documentsFolderFilter])

  const filter = useMemo(() => documentsFilter.split('+').filter(s => s), [documentsFilter])

  const statusFilters = useMemo(
    () => filter.reduce((result, filter) => result.concat(filter.slice(0, 6) === 'status' ? filter.slice(7) : []), [] as string[]),
    [filter]
  )

  const folderFilteredData = useMemo(() => {
    if (!activeFolderFilter) return data || []
    if (activeFolderFilter === DOCUMENTS_FILTERS.MINE) return data?.filter(({ category, authorId }) => !category && authorId === userId) || []
    if (activeFolderFilter === DOCUMENTS_FILTERS.SHARED)
      return data?.filter(({ category, sharedWith }) => !category && sharedWith?.includes(userId!)) || []
    return data?.filter(({ category }) => category === activeFolderFilter) || []
  }, [activeFolderFilter, data, userId])

  const filteredData = useMemo(
    () =>
      statusFilters.includes('trashed')
        ? trashed || []
        : folderFilteredData?.reduce(
            (result, datum) => result.concat(filterCheck(statusFilters, 'status', datum as { [K in keyof IListDocument]: unknown }) ? datum : []),
            [] as IListDocument[]
          ),
    [statusFilters, folderFilteredData, trashed]
  )

  const documents = useMemo(
    () =>
      filteredData?.map(document => (
        <DocumentThumbnail
          key={`MainLayout-Components-Documents-Thumbnail-${document?.id}`}
          id={document?.id}
          type={document?.type}
          selected={document?.id === selectedDocumentId}
          onClick={() => setSelectedDocumentId(document?.id!)}
          name={document?.name}
          edited={document?.edited}
          isPublic={document?.isPublic}
          mutation={document?.mutating === true ? document.mutation : undefined}
        />
      )) || [],
    [filteredData, selectedDocumentId, setSelectedDocumentId]
  )

  const viewToggleButtonContent = useMemo(() => {
    const nextIndex = (VIEW_STYLES.indexOf(view) + 1) % VIEW_STYLES.length
    const nextView = VIEW_STYLES[nextIndex]
    //@ts-ignore
    return [VIEW_ICONS[nextIndex], nextView.slice(0, 1).toUpperCase() + nextView.slice(1).replaceAll('-', ' ')]
  }, [view])

  return (
    <div className={classes.wrapper}>
      <div className={classes.actionBar.wrapper}>
        <Breadcrumbs
          folders={[
            { id: 'all', name: 'All documents' } as BEFolder,
            { id: DOCUMENTS_FILTERS.MINE, name: 'My documents' } as BEFolder,
            { id: DOCUMENTS_FILTERS.SHARED, name: 'Shared with me' } as BEFolder,
          ].concat(folders || [])}
          activePath={documentsFolderFilter || 'all'}
          onClick={id => documentsFolderFilter && setDocumentsFolderFilter(id)}
        />
        <Dropdown className={classes.actionBar.filter} label="Status">
          {Object.values(DOCUMENT_STATUSES).map(status => {
            const active = Boolean(statusFilters.find(filter => filter === status))
            return (
              <div key={`MainLayout-Content-Documents-filterDropdown-status-${status}`} className={classes.actionBar.filterButtonGroup}>
                <Button
                  onClick={event => {
                    // if (status === DOCUMENT_STATUSES.TRASHED) setDocumentsFolderFilter([])
                    setDocumentsFilter(`status:${status}`)
                    // const extraButton = ((event.currentTarget as HTMLButtonElement).parentElement?.parentElement as HTMLDivElement)?.querySelector(
                    //   `.${classes.actionBar.filterExtraButton} > .Casus-Components-Button-button`
                    // ) as HTMLButtonElement
                    // extraButton?.focus()
                  }}
                  disabled={active}
                  onClickBlur
                >
                  <span>{STATUS_MAP[status]}</span>
                </Button>
                {/* {statusFilters.length && (status === DOCUMENT_STATUSES.TRASHED ? active : statusFilters[0] !== DOCUMENTS_FILTERS.TRASHED) ? ( */}
                <Button
                  className={classes.actionBar.filterExtraButton}
                  onClick={() => setDocumentsFilter([`-status:${DOCUMENTS_FILTERS.TRASHED}`, `${active ? '-' : '+'}status:${status}`])}
                  dataSet={{
                    active: active ? '' : undefined,
                    visible: statusFilters?.length && statusFilters[0] !== DOCUMENTS_FILTERS.TRASHED ? '' : undefined,
                  }}
                  onClickBlur
                >
                  <Plus />
                </Button>
                {/* ) : null} */}
              </div>
            )
          })}
        </Dropdown>
        <Button onClick={() => setView(prev => VIEW_STYLES[(VIEW_STYLES.indexOf(prev) + 1) % VIEW_STYLES.length])} onClickBlur transparent>
          {viewToggleButtonContent}
        </Button>
      </div>
      {view === VIEW_STYLES[0] ? (
        <Grid isLoading={isLoading} isFetching={isFetching} error={errorMessage}>
          {documents.length ? documents : <span>No available documents</span>}
        </Grid>
      ) : null}
      {view === VIEW_STYLES[1] ? (
        <Table
          data={(filteredData as { [K in keyof IListDocument]: unknown }[]) || []}
          header
          defaultSort={{ key: 'edited._seconds', direction: 'descending' }}
          rowDataSet={['mutating', 'mutation']}
        >
          <Column
            className={classes.table.nameColumn}
            valueKey="name"
            header="Name"
            render={(value, entry, dataSet) => (
              <button
                type="button"
                onClick={() => {
                  if (entry.type === 'pdf') return history.push(`/pdf/${entry.id}`)
                  history.push(
                    `/${DOCUMENT_DIRECTORY}/${entry.templateId}/${(entry as { [K in keyof IDocument]: unknown }).id}${
                      entry.status === DOCUMENT_STATUSES.DRAFT ? '?step=configure' : '/preview'
                    }`
                  )
                }}
                disabled={dataSet.mutating !== undefined}
              >
                <Document />
                <span>{String(value)}</span>
              </button>
            )}
            sort
          />
          <Column
            className={classes.table.typeColumn}
            valueKey="type"
            header="Type"
            render={value => <span>{`${TYPE_MAP[value as keyof typeof TYPE_MAP]}`}</span>}
            width="min-content"
          />
          <Column
            className={classes.table.ownerColumn}
            valueKey="authorId"
            header="Owner"
            render={(_, entry) => <Profile profile={entry.author as Author} />}
            width="min-content"
          />
          <Column
            className={classes.table.sharingColumn}
            valueKey="sharedWithDetails"
            header="Sharing"
            render={(sharedWithDetails, entry) => {
              const people = ((sharedWithDetails || []) as Author[]).slice(0, 3).map(profile => <Profile profile={profile} />)
              if ((sharedWithDetails as Author[])?.length > 3)
                people.push(<Profile profile={{ firstName: '+', lastName: String((sharedWithDetails as Author[]).length - 3) }} />)
              if (entry.isPublic) people.unshift(<Public />)
              return <div>{people}</div>
            }}
            width="min-content"
          />
          <Column
            className={classes.table.statusColumn}
            valueKey="status"
            header="Status"
            render={value => <span>{`${STATUS_MAP[value as DocumentStatus]}`}</span>}
            width="min-content"
          />
          <Column
            className={classes.table.modifiedColumn}
            valueKey="edited._seconds"
            header="Modified"
            render={value => String(DateTime.fromMillis(Number(value) * 1000).toRelative())}
            width="min-content"
            sort
          />
          <Column
            className={classes.table.selectColumn}
            valueKey="id"
            header=""
            render={id => (
              <button type="button" onClick={() => setSelectedDocumentId(id as string)}>
                <VerticalEllipsis />
              </button>
            )}
            width="min-content"
          />
        </Table>
      ) : null}
    </div>
  )
})

Documents.displayName = 'MainLayout-Content-Documents'

export default Documents
