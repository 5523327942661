import React, { FunctionComponent } from 'react'

import { useDocument } from '___hooks'
import { Button } from 'components/CasusComponents'
import useModal from 'Modal'
import { MainLayoutRightPaneDocumentRelocationProps, mainLayoutRightPaneDocumentRelocationClasses as classes } from 'Layouts/___MainLayout'
import ModalContent from './Relocation.ModalContent'

export const Relocation: FunctionComponent<MainLayoutRightPaneDocumentRelocationProps> = React.memo(({ id }) => {
  const { update, updating } = useDocument(id)

  const open = useModal({
    header: false,
    content: (...callbacks) => <ModalContent id={id} cancel={callbacks[1]} conclude={callbacks[2]} />,
    onConclude: (_, category) => update({ id, category: category as string }),
    footer: false,
  })

  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>
        Relocation
        <Button onClick={() => open()} onClickBlur disabled={updating} loading={updating}>
          Move
        </Button>
      </span>
    </div>
  )
})

Relocation.displayName = 'MainLayout-RightPane-Document-Relocation'

export default Relocation
