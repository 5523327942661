import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { MutationOptions, useMutation, useQueryClient } from 'react-query'

import { IDocument } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type DuplicateDocumentVariables = string
export type DuplicateDocumentContext = { mutationId: string }
export type DuplicateDocumentMutationOptions = MutationOptions<IDocument | void, unknown, DuplicateDocumentVariables, DuplicateDocumentContext>
export type DuplicateDocumentFunctionType = (options?: DuplicateDocumentMutationOptions) => void
// const duplicateDocumentMutationFunction = (variables: DuplicateDocumentVariables) => documentsAPI.duplicateDocument(variables)

export const useDuplicateDocument = (id?: string | null) => {
  const queryClient = useQueryClient()
  const mutationId = useMemo(uuid, [])

  const updateListing = (method: (data: IDocument[] | undefined) => IDocument[]) => queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: DuplicateDocumentVariables) => {
    const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument
    const moment = { _seconds: Math.floor(Date.now() / 1000) }
    const optimisticPayload = { id: mutationId, created: moment, edited: moment, mutating: true, mutation: 'create' }
    const optimisticDocument = Object.assign(JSON.parse(JSON.stringify(currentDocument || {})), optimisticPayload)
    queryClient.setQueryData([QUERY_KEYS.DOCUMENT, mutationId], optimisticDocument)
    updateListing(data => [optimisticDocument].concat(data || []))
    return { mutationId }
  }

  const onError = (error: unknown, variables: DuplicateDocumentVariables, context: DuplicateDocumentContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    updateListing((data: IDocument[] | undefined) => data?.filter(({ id }) => id !== context?.mutationId) || [])
  }

  const onSuccess = (document: IDocument | void, variables: DuplicateDocumentVariables, context: DuplicateDocumentContext | undefined) => {
    // queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    // queryClient.resetQueries([QUERY_KEYS.DOCUMENT, context?.mutationId])
    // @ts-ignore
    queryClient.setQueryData([QUERY_KEYS.DOCUMENT, document?.id], document)
    updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === context?.mutationId, document))
  }

  const documentDuplicateMutation = useMutation<IDocument | void, unknown, DuplicateDocumentVariables, DuplicateDocumentContext>(
    [QUERY_KEYS.DOCUMENT, mutationId],
    documentsAPI.duplicateDocument,
    { onMutate, onError, onSuccess }
  )

  const duplicateMutationFunction: DuplicateDocumentFunctionType = options => documentDuplicateMutation.mutate(id!, options)

  return { duplicate: duplicateMutationFunction, duplicating: documentDuplicateMutation.isLoading }
}

export default useDuplicateDocument
