import { v4 as uuid } from 'uuid'
import { MutateOptions, useMutation, useQueryClient } from 'react-query'

import { DOCUMENT_STATUSES, IDocument } from '___types'
import { replaceInArray } from 'utilities/helpers'
import { documentsAPI } from '___api'
import { QUERY_KEYS } from '___queries'

export type SubmitDocumentVariables = { id: string; publicFlow?: boolean; updateCategory?: string }
export type SubmitDocumentContext = { mutationId: string }
export type SubmitDocumentMutationOptions = MutateOptions<IDocument, unknown, SubmitDocumentVariables, SubmitDocumentContext>
export type SubmitDocumentFunctionType = (options?: SubmitDocumentMutationOptions) => void
const submitDocumentMutationFunction = (variables: SubmitDocumentVariables) => documentsAPI.submitDocument(variables.id, variables.publicFlow)

export const useSubmitDocument = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const documentQueryKey = [QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])

  const updateListing = (method: (data: (IDocument & { mutationId?: string })[] | undefined) => IDocument[]) =>
    queryClient.setQueryData([QUERY_KEYS.DOCUMENTS], method)

  const onMutate = (variables: SubmitDocumentVariables) => {
    const mutationId = uuid()
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original?: IDocument }
    if (currentDocument) {
      const originalDocument = currentDocument.original || currentDocument
      const optimisticDocument = Object.assign({}, originalDocument, {
        status: DOCUMENT_STATUSES.LOCKED,
        mutationId,
        mutating: true,
        mutation: 'update',
        original: originalDocument,
      })
      queryClient.setQueryData(documentQueryKey, optimisticDocument)
      if (!publicFlow) updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, optimisticDocument))
    }
    return { mutationId }
  }

  const onError = (error: unknown, variables: SubmitDocumentVariables, context: SubmitDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) {
      queryClient.removeQueries(documentQueryKey)
      queryClient.resetQueries(documentQueryKey)
      // queryClient.setQueryData(documentQueryKey, currentDocument.original)
      if (!publicFlow) {
        queryClient.cancelQueries([QUERY_KEYS.DOCUMENTS])
        queryClient.invalidateQueries([QUERY_KEYS.DOCUMENTS])
        queryClient.fetchQuery([QUERY_KEYS.DOCUMENTS])
        // updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id, currentDocument.original))
      }
    }
  }

  const onSuccess = (document: IDocument | undefined, variables: SubmitDocumentVariables, context: SubmitDocumentContext | undefined) => {
    const currentDocument = queryClient.getQueryData(documentQueryKey) as IDocument & { mutationId?: string; original: IDocument }
    if (currentDocument && currentDocument.mutationId === context?.mutationId) queryClient.setQueryData(documentQueryKey, document)
    if (!publicFlow)
      updateListing(data => replaceInArray(data?.slice() || [], datum => datum.id === id && datum.mutationId === context?.mutationId, document))
  }

  const documentSubmitMutation = useMutation<IDocument, unknown, SubmitDocumentVariables, SubmitDocumentContext>(
    documentQueryKey,
    submitDocumentMutationFunction,
    { onMutate, onError, onSuccess }
  )

  const submitMutationFunction: SubmitDocumentFunctionType = options => documentSubmitMutation.mutate({ id: id!, publicFlow }, options)

  return { submit: submitMutationFunction, submitting: documentSubmitMutation.isLoading }
}

export default useSubmitDocument
